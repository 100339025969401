import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../../core/components/controls/button/button';
import { ClientRoutes } from '../../../../core/models/enums/client-routes.enum';
import { LocalStorageTypes } from '../../../../core/models/enums/local-storage.enum';
import { setItemLocalStorage } from '../../../../core/services/local-storage.service';
import { SnackbarState, showSnackbar } from '../../../../redux-store/slices/snackbar.slice';
import { AuthorizeResponseDto } from '../../models/dtos/authorize-response.dto';
import { authorize } from '../../services/auth.service';
import { FacebookLoginButton } from '../facebook-login-button/facebook-login-button';
import { GoogleLoginButton } from '../google-login-button/google-login-button';

export const UserLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onClickSignIn = (): void => {
    const signIn = async (): Promise<void> => {
      const response: AuthorizeResponseDto | undefined = await authorize({
        email: username,
        password: password,
      });

      if (response) {
        setItemLocalStorage(LocalStorageTypes.JWT_TOKEN, response.access_token);
        navigate(ClientRoutes.PORTFOLIO);
        // Refresh page, that menu items are displayed
        navigate(0);
      } else {
        dispatch(
          showSnackbar({
            message: 'Benutzername oder Password ist falsch',
            severety: 'error',
          } as SnackbarState)
        );
      }
    };

    signIn();
  };

  /** Listen to key enter  */
  useEffect(() => {
    const listener = (event: KeyboardEvent): void => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        onClickSignIn();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
        <h1 className='text-center max-w-xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-4xl text-secondary'>
          Anmelden
        </h1>
      </div>

      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        <div className='space-y-6'>
          {/* Email Input */}
          <div>
            <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
              E-Mail
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                value={username}
                autoComplete='email'
                placeholder='E-Mail'
                required
                className='block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>

          {/* Password Input */}
          <div>
            <div className='flex items-center justify-between'>
              <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>
                Password
              </label>
              <div className='text-sm'>
                <Link to={ClientRoutes.USER_FORGOT_PASSWORD} className='font-semibold'>
                  Passwort vergessen?
                </Link>
              </div>
            </div>
            <div className='mt-2'>
              <input
                id='password'
                name='password'
                type='password'
                value={password}
                autoComplete='current-password'
                placeholder='Password'
                required
                className='block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <Button type='submit' onClick={() => onClickSignIn()}>
            Anmelden
          </Button>

          <p className='mt-10 text-center text-sm text-gray-500'>
            <span>Noch keinen Account? </span>
            <Link to={ClientRoutes.USER_REGISTRATION} className='font-semibold leading-6'>
              Registrieren
            </Link>
          </p>

          <GoogleLoginButton></GoogleLoginButton>
          <FacebookLoginButton></FacebookLoginButton>
        </div>
      </div>
    </div>
  );
};
