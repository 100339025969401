// import GoogleIcon from '@mui/icons-material/Google';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../core/components/controls/button/button';
import { ClientRoutes } from '../../../../core/models/enums/client-routes.enum';
import { LocalStorageTypes } from '../../../../core/models/enums/local-storage.enum';
import { setItemLocalStorage } from '../../../../core/services/local-storage.service';
import { AuthorizeResponseDto } from '../../models/dtos/authorize-response.dto';
import { OAuth2AuthorizeDto } from '../../models/dtos/oauth2-authorize.dto';
import { oauth2Facebook } from '../../services/auth.service';

import { ReactComponent as FacebookIcon } from '../../../../../assets/svg/facebook-icon.svg';

export const FacebookLoginButton: React.FC = () => {
  const navigate = useNavigate();
  const redirectURI = `https://tradesyze.de/user/login?oauth=fb`;

  useEffect(() => {
    const urlSearchQuery = new URLSearchParams(window.location.search);
    const code = urlSearchQuery.get('code');
    const oauth = urlSearchQuery.get('oauth');

    if (code && oauth === 'fb') {
      const signIn = async (): Promise<void> => {
        const oauthAuthorizeDto: OAuth2AuthorizeDto = {
          code: code,
          redirect_uri: redirectURI,
        };
        const response: AuthorizeResponseDto | undefined = await oauth2Facebook(oauthAuthorizeDto);

        if (response) {
          setItemLocalStorage(LocalStorageTypes.JWT_TOKEN, response.access_token);
          navigate(ClientRoutes.PORTFOLIO);
          // Refresh page, that menu items are displayed
          navigate(0);
        }
      };

      signIn();
    }
  }, [window.location.search]);

  const onClickSignIn = (): void => {
    const client_id = '548857377738257';
    const facebookOauth2Url = `https://www.facebook.com/v21.0/dialog/oauth?client_id=${client_id}&redirect_uri=${redirectURI}&response_type=code&scope=email,public_profile`;
    window.location.assign(facebookOauth2Url);
  };

  return (
    <Button type='submit' onClick={() => onClickSignIn()} icon={<FacebookIcon />}>
      <span className='mx-2'>Facebook</span>
    </Button>
  );
};
