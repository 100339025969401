import { mwApiInstance } from '../../../core/services/axios.middleware';
import { AuthorizeResponseDto } from '../models/dtos/authorize-response.dto';
import { AuthorizeDto } from '../models/dtos/authorize.dto';
import { OAuth2AuthorizeDto } from '../models/dtos/oauth2-authorize.dto';

export const authorize = async (authorize: AuthorizeDto): Promise<AuthorizeResponseDto | undefined> => {
  const response = await mwApiInstance.post(`/auth/authorize`, authorize).catch(() => {
    return undefined;
  });
  return response?.data;
};

export const oauth2Google = async (oAuth2Authorize: OAuth2AuthorizeDto): Promise<AuthorizeResponseDto> => {
  const response = await mwApiInstance.post(`/auth/google`, oAuth2Authorize);
  return response?.data;
};

export const oauth2Facebook = async (oAuth2Authorize: OAuth2AuthorizeDto): Promise<AuthorizeResponseDto> => {
  const response = await mwApiInstance.post(`/auth/facebook`, oAuth2Authorize);
  return response?.data;
};
